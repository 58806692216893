@import "/src/style/common";

.form {
  padding-top: 20px;
  &-section {
    margin-top: 80px;
    &-title {
      position: relative;
      margin-bottom: 40px;
      padding-bottom: 20px;
      border-bottom: 2px solid $basic350;
      @include h7;
      .form-mandatory {
        position: absolute;
        right: 0;
        top: 6px;
        @include b2;
      }
    }
  }
  &-list {
    &-item {
      width: 100%;
      margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      // &:last-child {
      //   margin-bottom: 0;
      // }
      &-label {
        width: 180px;
        @include b1;
        font-weight: 700;
        color: $basic800;
        &.align-top {
          padding-top: 12px;
          align-self: flex-start;
        }
      }
      input {
        flex: 1;
        padding: 12px 16px;
        background-color: $basic350;
        border-radius: 8px;
        border: 2px solid $basic350;
        color: #fff;
        @include b1;
        &:hover {
          border: 2px solid #fff;
        }
        &:focus {
          border: 2px solid $primary100;
        }
        &::placeholder {
          color: $basic500;
        }
        &:disabled {
          color: $basic600;

          &:hover {
            border: 2px solid $basic350;
          }
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox  */
      input[type="number"] {
        -moz-appearance: textfield;
      }
      textarea {
        flex: 1;
        overflow: auto;
        min-height: 364px;
        resize: none;
        padding: 12px 16px;
        background-color: $basic350;
        border-radius: 8px;
        border: 2px solid $basic350;
        color: #fff;
        @include b1;
        &:hover {
          border: 2px solid #fff;
        }
        &:focus {
          border: 2px solid $primary100;
        }
        &::placeholder {
          color: $basic500;
        }
        &:disabled {
          color: $basic600;

          &:hover {
            border: 2px solid $basic350;
          }
        }
      }
      .MuiInputBase-root {
        flex: 1;
        height: 52px;
        border-radius: 8px;
        background-color: $basic350;
        @include b1;
        color: #fff;
        .MuiOutlinedInput-notchedOutline {
          top: 0;
          border: 1px solid $basic350;
        }
        .MuiSelect-icon {
          color: #fff;
        }
      }
      &-terms {
        width: 100%;
        padding: 12px 16px;
        height: 100px;
        overflow-y: scroll;
        border-radius: 8px;
        background-color: $basic350;
        &-text {
          @include b2;
          color: #fff;
          text-align: justify;
        }
      }
      &-checkbox {
        flex: 1;
        .checkbox-label {
          margin: 0 20px;
          @include b1;
          vertical-align: middle;
        }
      }
      .invalid {
        width: 100%;
        margin: 8px 0 0;
        padding-left: 190px;
        @include b4;
        color: #a24235;
      }
      .invalidTop {
        padding-left: 20px;
        @include b4;
        color: #a24235;
      }
    }
  }
  &-submit-btn {
    margin-top: 60px;
    text-align: right;
  }
}

.wrapper {
  padding-left: 180px;
}

.asterisk {
  margin: 0 8px;
  color: $primary100;
  vertical-align: middle;
  @include b0;
}

.highlight {
  color: $primary200;
}

@media all and (min-width: 481px) and (max-width: 820px) {
  .form {
    &-list {
      &-item {
        &-label {
          width: 118px;
        }
      }
    }
  }

  .wrapper {
    padding-left: 118px;
  }
}

@media screen and (max-width: 480px) {
  .form {
    padding-top: 0px;
    &-list {
      &-item {
        width: 100%;
        display: block;
        &-label {
          width: 100%;
          display: block;
          margin-bottom: 10px;
          &.align-top {
            padding-top: 0px;
          }
        }
        input[type="text"] {
          width: 100%;
        }
        textarea {
          width: 100%;
        }
        .MuiInputBase-root {
          width: 100%;
        }
      }
    }
  }

  .wrapper {
    padding-left: 0px;
  }
}
