// colors
$basic100: #1A1B1E;
$basic150: #1F2024;
$basic200: #2F3137;
$basic300: #34363C;
$basic350: #3F424A;
$basic400: #525660;
$basic450: #6A6F7C;
$basic500: #757B8A;
$basic600: #969BA6;
$basic700: #B7BAC2;
$basic800: #E3E5E8;
$basic900: #F1F2F3;
$white: #FFFFFF;

$primary100: #3D6AFF;
$primary200: #7091FF;
$primaryHover: #1A4FFF;
$primaryPressed: #003BFF;

$stateError: #A24235;
$stateSafe: #239C64;

$dashboardBlue: #0E63FE;
$dashboardPink: #9840CC;

$bg100: #1A1B1E;
$bg150: #1F2024;
$bg200: #2F3137;
$bg300: #34363C;
$bgModal: #0C0C0EB2;

// size
@mixin h1 {
  font-size: 60px;
  letter-spacing: -1.5px;
  line-height: 96px;
  font-weight: 800;
}
@mixin h2 {
  font-size: 54px;
  line-height: 82px;
  letter-spacing: -1.35px;
  font-weight: 800;
}
@mixin h3 {
  font-size: 44px;
  line-height: 66px;
  letter-spacing: -1.1px;
  font-weight: 700;
}
@mixin h4 {
  font-size: 36px;
  line-height: 46px;
  letter-spacing: -0.9px;
  font-weight: 700;
}
@mixin h5 {
  font-size: 32px;
  line-height: 42px;
  letter-spacing: -0.8px;
  font-weight: 700;
}
@mixin h6 {
  font-size: 28px;
  line-height: 36px;
  letter-spacing: -0.7px;
  font-weight: 400;
}
@mixin h7 {
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.6px;
  font-weight: 400;
}
@mixin h8 {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  font-weight: 400;
}
@mixin b0 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.45px;
  font-weight: 400;
}
@mixin b1 {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  font-weight: 400;
}
@mixin b2 {
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.35px;
  font-weight: 400;
}
@mixin b3 {
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.32px;
  font-weight: 400;
}
@mixin b4 {
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
}