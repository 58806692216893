@import "/src/style/common";

// 레이아웃
main {
  iframe {
    width: 100%;
    height: calc(100vh - 81px);
  }
}

.bg-flex-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 60px;
  margin-top: 50px;
  img {
    width: 70%;
  }
}

.bg-image {
  width: 100%;
  height: 200px;
  background-color: red;
}

.page-title {
  margin: 0 0 30px;
  font-size: 54px;
  line-height: 82px;
  letter-spacing: -1.35px;
  font-weight: 800;
  text-align: center;
}

.page-subTitle {
  font-size: 20px;
  line-height: 26px;
  letter-spacing: -0.5px;
  font-weight: 400;
  color: #969ba6;
  text-align: center;
}
