@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/src/fonts/Poppins-Regular.woff") format("woff");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("/src/fonts/Poppins-Bold.woff") format("woff");
}
* {
  box-sizing: border-box;
  border: 0;
  margin: 0;
  padding: 0;
}
body {
  background-color: #1a1b1e;
}
button,
input,
select,
textarea {
  outline: none;
  cursor: pointer;
}
body,
button,
input,
select,
table,
textarea {
  font-family: "AppleSDGothic", sans-serif;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.4px;
  font-weight: 400;
  color: #fff;
}
html,
body,
main,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
ol,
ul,
li {
  list-style: none;
}
a {
  text-decoration: none;
  color: black;
  display: inline-block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  white-space: nowrap;
}
span {
  display: inline-block;
}
img {
  vertical-align: middle;
}
textarea {
  outline: none;
}

/* 아이폰 input 둥근테두리 그림자 제거 */
input {
  -webkit-appearance: none;
  border-radius: 0;
  -webkit-border-radius: 0;
  outline: none;
}
input[type="checkbox"] {
  -webkit-appearance: checkbox;
}
input[type="radio"] {
  -webkit-appearance: radio;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 1000px #2f3137 inset !important;
  -webkit-box-shadow: 0 0 0 1000px #2f3137 inset !important;
  -webkit-text-fill-color: #e3e5e8;
  border: none;
}

/* select 화살표 없애기 */
select {
  outline: none;
  background: white;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
}
select::-ms-expand {
  display: none;
}
fieldset {
  border: 0;
}
legend {
  display: none;
}
main {
  margin-top: 81px;
}
main.has-margin {
  margin-left: 288px;
  min-width: 1279px;
}

.content-container {
  margin-left: 288px;
}
.error-message {
  margin: 8px 0 12px;
  padding-left: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #a24235;
}
.material-symbols-outlined {
  font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}

/* 레이아웃 */
.bg-container {
  width: 100%;
  padding: 52px;
  background-color: #2f3137;
  min-height: calc(100vh - 81px);
  margin-top: 81px;
}
.container {
  /* max-width: 1632px; */
}
.inner {
  max-width: 80%;
  margin: 0 auto;
}
.inner-fixed {
  max-width: 1280px;
  margin: 0 auto;
}
.inner-inquiry {
  width: 70%;
  margin-top: 60px;
}
.highlight-blue {
  position: relative;
  margin: 0 8px;
  z-index: 1;
}
.highlight-blue::before {
  content: "";
  position: absolute;
  z-index: -1;
  left: -2px;
  top: 50%;
  width: calc(100% + 6px);
  height: 40%;
  background-color: #3d6aff;
  transform: skew(-15deg);
  border-radius: 4px;
}

@media screen and (max-width: 1279px) {
  .bg-container {
    padding: 32px;
  }
  .inner-inquiry {
    margin: 0 auto 150px;
  }
}
