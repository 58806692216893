@import "/src/style/common";

.loginContainer {
  width: 100%;
  height: calc(100vh - 81px);
  display: flex;
  justify-content: center;
  align-items: center;
  .loginBox {
    width: 480px;
    .loginTitle {
      text-align: center;
      margin-bottom: 16px;
      h3 {
        font-family: "Poppins";
        font-weight: 700;
        font-size: 50px;
        line-height: 80px;
        color: $basic800;
      }
    }
    .loginType {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      button {
        width: 50%;
      }
      .Mui-selected {
        color: white;
        background-color: #3d6aff;
      }
    }

    .loginInputList {
      .loginInputItem {
        margin-bottom: 16px;
        position: relative;
        &:last-child {
          margin-bottom: 0px;
        }
        .MuiTextField-root {
          width: 100%;
          height: 68px;
          background-color: $basic200;
          border-radius: 8px;
          .MuiInputLabel-root {
            padding: 0 20px;
            @include b1;
            color: $basic400;
            &.MuiInputLabel-shrink {
              padding-top: 14px;
              @include b2;
              color: $basic400;
            }
          }
          .MuiInput-root {
            margin: 9px;
            &::before {
              border-bottom: 0;
            }
            .MuiInput-input {
              padding: 18px 20px;
              font-family: "Poppins";
              @include b1;
              color: $basic800;
            }
          }
        }
        .showPassword {
          position: absolute;
          top: 24px;
          right: 20px;
          font-family: "Poppins";
          @include b1;
          color: $primary100;
          cursor: pointer;
        }
      }
    }
    .rememberEmailContainer {
      margin: 16px 0 32px;
      .rememberEmail {
        cursor: pointer;
        img {
          filter: grayscale(100%);
        }
        span {
          margin-left: 10px;
          @include b2;
          color: $basic500;
          transition: 0.3s;
        }
        &:hover {
          span {
            color: $basic600;
          }
        }
        &.active {
          img {
            filter: grayscale(0%);
          }
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .loginContainer {
    .loginBox {
      width: 100%;
      padding: 0 24px;
      .loginTitle {
        h3 {
          @include h4;
        }
      }
    }
    .loginType {
      display: flex;
      width: 100%;
      margin-bottom: 20px;

      button {
        width: 50%;
      }
      .Mui-selected {
        color: white;
        background-color: #3d6aff;
      }
    }
    .loginInputList {
      .loginInputItem {
        .MuiTextField-root {
          .MuiInputLabel-root {
            padding: 0 12px;
            @include b2;
          }
          .MuiInput-root {
            .MuiInput-input {
              @include b2;
            }
          }
        }
        .showPassword {
          @include b2;
        }
      }
    }
  }
}
