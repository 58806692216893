@import "/src/style/common";

.side-menu-container {
  position: fixed;
  left: 0;
  top: 0;
  width: 288px;
  height: 100vh;
  overflow-y: auto;
  padding-top: 81px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &::-webkit-scrollbar {
    display: none;
  }
  .slide-menu-list {
    padding: 32px 16px;
    .MuiAccordion-root {
      margin: 0;
      background-color: transparent;
      .MuiAccordionSummary-root {
        width: 100%;
        height: 72px;
        padding: 0 24px;
        border-radius: 8px;
        cursor: pointer;
        &.active {
          background-color: $primary100;
          .MuiTypography-root {
            color: $white;
          }
        }
        .MuiTypography-root {
          @include b0;
          color: $basic700;
          font-weight: 700;
        }
      }
      .MuiAccordionDetails-root {
        .sub-menu-item {
          padding-left: 32px;
          cursor: pointer;
          .sub-menu {
            display: block;
            padding: 12px 16px 12px 0;
            @include b1;
            color: $basic400;
            cursor: pointer;
            &.active {
              color: $basic800;
            }
          }
          .second-sub-menu-list {
            .second-sub-menu-item {
              padding: 8px 0 8px 16px;
              border-left: 2px solid $basic350;
              border-radius: 1px;
              span {
                @include b1;
                color: $basic400;
              }
              &.active {
                border-left: 2px solid $primary100;
                span {
                  color: $basic800;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1279px) {
  // .side-menu-container {
  //   display: none;
  // }
}
